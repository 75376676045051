export const langTextSize = {
  mobile: "5vw",
  desktop: "1.25rem",
};

export const steps = {
  de: ["Bestellung", "Kontakt", "Übersicht"],
  en: ["Order", "Contact", "Overview"],
};

export const digSeparator = (l) => {
  return l === "de" ? "," : ".";
};

export const buttonText = {
  back: {
    de: "Zurück",
    en: "Back",
  },
  next: {
    de: "Weiter",
    en: "Next",
  },
  end: {
    de: "Kostenpflichtig bestellen",
    en: "Order for a fee",
  },
  exit: {
    de: "Beenden",
    en: "Exit",
  },
};

export const pageText = {
  book: {
    de: "Taekwondo-Buch",
    en: "Taekwondo-Book",
  },
  alert: {
    de: "Hoppla! Da ist wohl etwas schief gelaufen. Bitte probieren Sie es erneut.\nSollte das Problem weiterhin bestehen, prüfen Sie bitte Ihre Internetverbindung.",
    en: "Oops! Something went wrong. Please try again.\nCheck your internet connection if the problem still exists.",
  },
  order: {
    de: "Buch bestellen",
    en: "Order book",
  },
};

export const orderPageText = {
  desc1: {
    de: 'Drücken Sie auf "In den Warenkorb" um ein Buch dem Warenkorb hinzuzufügen. Durch mehrmaliges klicken, fügen Sie mehrere Bücher hinzu.',
    en: 'Add a book by pressing "Add to Cart". You can add more books by pressing the button again.',
  },
  desc2: {
    de: 'Möchten Sie eine Widmung hinzufügen, so setzen Sie einen Haken bei "Das Buch soll eine Widmung enthalten" und tragen Sie die Widmung(en) ein.',
    en: 'To add a dedication to the book, check the box at "The book contains a dedication" and add the dedication (e.g. name surname, taekwondo center).',
  },
  checkBox: {
    de: " Das Buch soll eine Widmung enthalten",
    en: "The book contains a dedication",
  },
  dedicationField: {
    title: {
      de: "Widmung",
      en: "Dedication",
    },
    label: {
      de: "Widmungstext",
      en: "Dedication text",
    },
    placeHoler: {
      de: "(z.B. Vorname Nachname, Taekwondo Centername)",
      en: "(z.B. firstname surname, taekwondo center)",
    },
    helper: {
      de: "Maximal 30 Zeichen",
      en: "30 chars maximum",
    },
  },
  cart: {
    title: {
      de: "Warenkorb",
      en: "Cart",
    },
    add: {
      de: "In den Warenkorb",
      en: "Add to cart",
    },
    empty: {
      de: "Noch keine Bestellungen im Warenkorb",
      en: "No order yet",
    },
    remove: {
      de: "Aus Warenkorb entfernen",
      en: "Remove from Cart",
    },
    sum: {
      de: "Summe",
      en: "Sum",
    },
  },
  total: {
    de: "Gesamt",
    en: "Total",
  },
};

export const addressFormText = {
  title: {
    de: "Kontaktdetails",
    en: "Contact details",
  },
  labels: {
    fN: {
      de: "Vorname",
      en: "First name",
    },
    lN: {
      de: "Nachname",
      en: "Surname",
    },
    phone: {
      de: "Telefon",
      en: "Phone",
    },
    address1: {
      de: "Adresse",
      en: "Adress",
    },
    address2: {
      de: "Adresszusatz",
      en: "Additional adress",
    },
    zip: {
      de: "PLZ",
      en: "ZIP",
    },
    city: {
      de: "Stadt",
      en: "City",
    },
    country: {
      de: "Land",
      en: "Country",
    },
  },
  submit: {
    de: "Bestätigen",
    en: "Submit",
  },
};

export const reviewPageText = {
  title: {
    de: "Bestellübersicht",
    en: "Order overview",
  },
  text: {
    shipping: {
      de: "Versand",
      en: "Shipping",
    },
    payment: {
      de: "Zahlungsmethode",
      en: "Payment",
    },
    paymentType: {
      de: "Überweisung",
      en: "Transfer",
    },
    paymentDetails: {
      de: "Weitere Details zur Überweisung erhalten Sie nach der Buchung und separat per Mail.",
      en: "You will receive further payment details for the order per mail.",
    },
  },
  shipping: {
    de: "Versandkosten",
    en: "shipping cost",
  },
  shippingType: {
    de: "Standardversand",
    en: "Standard shipping",
  },
  totalSum: {
    de: "Gesamtsumme",
    en: "Total sum",
  },
};

export const overViewPageText = {
  title: {
    de: "Vielen Dank für Ihre Bestellung",
    en: "Thank you for your order",
  },
  order: {
    title: {
      de: "Eine Bestellbestätigung wurde soeben an die von Ihnen hinterlegte E-Mail versendet.",
      en: "A confirmation mail of your order has been sent to your mail.",
    },
    orderNumberText: {
      de: "Ihre Bestellnummer lautet",
      en: "Your order number is",
    },
    transferText1: {
      de: "Bitte überweisen Sie den Betrag von ",
      en: "Please transfer the following amount of ",
    },
    transferText2: {
      de: "unter Angabe Ihrer Bestellnummer an folgende Bankverbindung:",
      en: "while providing your order number to the following bank account:",
    },
  },
  qr: {
    text: {
      de: "Oder öffnen Sie auf Ihrem Smartphone Ihre Banking-App und scannen diesen QR-Code um die Überweisung zu tätigen:",
      en: "Or open your Banking-App on your Smartphone and scan the QR Code for the transfer:",
    },
  },
  shipping: {
    de: "Wir informieren Sie, sobald das Paket versendet wurde.",
    en: "We inform you as soon as the package has been shipped",
  },
};
