import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { BANK_DATA } from "../../helpers/bankData";

export default function Bankdetails({ orderNumber, totalAmount }) {
  function createData(text, data) {
    return { text, data };
  }

  const rows = [
    createData("Inhaber", BANK_DATA.owner),
    createData("IBAN", BANK_DATA.iban),
    createData("BIC", BANK_DATA.bic),
    createData("Verwendungszweck", orderNumber),
    createData("Betrag", `${totalAmount},00 €`),
  ];

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.text}>
              <TableCell component="th" scope="row">
                <b>{row.text}:</b>
              </TableCell>
              <TableCell align="left">{row.data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
