import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckSlide from "./CheckSlide";
import {
  digSeparator,
  orderPageText,
  pageText,
} from "../../helpers/pageSettings";

export default function Order({
  orderState,
  handleChangeOrderState,
  language,
}) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState(orderState.order);
  var dedicationText = React.useRef("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddBook = () => {
    handleClickOpen();
    let newState,
      newOrder = {
        amount: 70,
        dedication:
          dedicationText.current && dedicationText.current.value
            ? dedicationText.current.value
            : false,
      };
    if (state.checkedDedication) {
      newState = {
        ...state,
        totalOrders: state.totalOrders + 1,
        dedicationOrders: [...state.dedicationOrders, newOrder],
      };
    } else {
      newState = {
        ...state,
        totalOrders: state.totalOrders + 1,
        orders: state.orders + 1,
      };
    }
    setState(newState);
    handleChangeOrderState({
      ...orderState,
      order: { ...newState },
      totalAmount: newState.totalOrders * 70 + 7,
    });
    if (dedicationText.current && dedicationText.current.value) {
      dedicationText.current.value = "";
    }
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleDeleteFromBasket = (deleteType) => {
    handleClickOpen();
    let newState = { ...state };
    let totalAmount = 0;
    if (deleteType === "REGULAR") {
      newState.totalOrders = state.totalOrders - state.orders;
      newState.orders = 0;
    } else {
      let dedicationOrders = state.dedicationOrders;
      if (deleteType.index > -1) {
        // only splice array when item is found
        dedicationOrders.splice(deleteType.index, 1); // 2nd parameter means remove one item only
      }
      newState.totalOrders = state.totalOrders - 1;
      totalAmount = newState.totalOrders * 70;
      newState.dedicationOrders = dedicationOrders;
    }

    setState(newState);
    handleChangeOrderState({
      ...orderState,
      order: { ...newState },
      totalAmount: totalAmount,
    });
    setTimeout(() => {
      handleClose();
    }, 1000);
  };

  return (
    <div>
      {open && <CheckSlide />}
      <Typography variant="h4">Bestellung</Typography>
      <Grid style={{ margin: 0 }} spacing={3} container>
        <Grid item xs={12}>
          <Typography variant="body1">
            {orderPageText.desc1[language]}
          </Typography>
          <Typography variant="body1">
            {orderPageText.desc2[language]}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography variant="h6">1x Taekwondo Buch</Typography>
          <Typography style={{ marginLeft: 25 }} variant="body1">
            1. - 11. Hyong
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography style={{ padding: 25 }} variant="h5" align="right">
            70{language === "de" ? "," : "."}00 €
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checkedDedication}
                onChange={handleChange}
                color="secondary"
                name="checkedDedication"
              />
            }
            label={orderPageText.checkBox[language]}
          />
        </Grid>
        {state.checkedDedication && (
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
              {orderPageText.dedicationField.title[language]}
            </Typography>
            <TextField
              required
              inputRef={dedicationText}
              label={orderPageText.dedicationField.label[language]}
              placeholder={orderPageText.dedicationField.placeHoler[language]}
              fullWidth
              multiline
              inputProps={{
                maxLength: 30,
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddBook();
                  }
                },
              }}
              helperText={orderPageText.dedicationField.helper[language]}
            />
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          <Button
            onClick={() => {
              handleAddBook();
            }}
            variant="contained"
            color="primary"
          >
            {orderPageText.cart.add[language]}
          </Button>
        </Grid>
      </Grid>
      <hr />
      <div style={{ marginTop: "30px" }}>
        <Typography variant="h5">
          {orderPageText.cart.title[language]}:
        </Typography>
        {state.totalOrders === 0 && (
          <Typography style={{ margin: 25 }} variant="h5">
            {orderPageText.cart.empty[language]}
          </Typography>
        )}
        {state.orders > 0 && (
          <Grid style={{ margin: 5, padding: 10 }} component={Paper} container>
            <Grid item md={6} xs={12}>
              <Typography variant="h6">
                {`${state.orders}x ${pageText.book[language]}`}
              </Typography>
              <Typography variant="body1">1. - 11. Hyong</Typography>
              <br />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                // style={{ marginRight: 25 }}
                variant="h5"
                align="right"
              >
                {`${state.orders}x `}70{language === "de" ? "," : "."}00 €
              </Typography>
              <Typography variant="h5" align="right">
                {`${orderPageText.cart.sum[language]}: ${
                  state.orders * 70
                }${digSeparator(language)}00 €`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ textAlign: "end" }}>
                <Button
                  onClick={() => {
                    handleDeleteFromBasket("REGULAR");
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {orderPageText.cart.remove[language]}
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
        {/* Map amount of dedicated orders here: */}
        {state.dedicationOrders.map((order, i) => {
          return (
            <Grid
              style={{ margin: 5, padding: 10 }}
              component={Paper}
              container
              key={i}
            >
              <Grid item md={6} xs={12}>
                <Typography variant="h6">{pageText.book[language]}</Typography>
                <Typography variant="body1">1. - 11. Hyong</Typography>
                <br />
                {order.dedication && (
                  <div>
                    <Typography variant="body1">
                      <b>Widmung:</b>
                    </Typography>
                    <Typography variant="body2">{order.dedication}</Typography>
                  </div>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography style={{ padding: 25 }} variant="h5" align="right">
                  70{language === "de" ? "," : "."}00 €
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "end" }}>
                  <Button
                    onClick={() => {
                      handleDeleteFromBasket({
                        type: "DEDICATION",
                        index: i,
                        order: order,
                      });
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    {orderPageText.cart.remove[language]}
                  </Button>
                </div>
              </Grid>
            </Grid>
          );
        })}
      </div>
      <div>
        <Typography style={{ margin: 20 }} align="right" variant="h5">
          <b>
            {`${orderPageText.total[language]}: ${state.totalOrders * 70}${
              language === "de" ? "," : "."
            }`}
            00 €{" "}
          </b>
        </Typography>
      </div>
    </div>
  );
}
