import "./App.css";
import FrontPage from "./components/frontpage/FrontPage";
import Checkout from "./components/checkout/Checkout";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import LanguageInfo from "./components/languageInfo/LanguageInfo";

function App() {
  const [language, setLanguage] = useState("de");

  const handleSwitchLanguage = (l) => {
    setLanguage(l);
  };

  return (
    <div>
      <LanguageInfo switchToEnglish={() => handleSwitchLanguage("en")} />
      <Routes>
        <Route
          path="/bestellen"
          element={
            <Checkout
              language={language}
              handleSwitchLanguage={handleSwitchLanguage}
            />
          }
        ></Route>
        <Route
          path="/"
          element={
            <FrontPage
              language={language}
              handleSwitchLanguage={handleSwitchLanguage}
            />
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
