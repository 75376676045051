import React from "react";
import QRCode from "qrcode.react";
import { BANK_DATA } from "../../helpers/bankData";

export default function BookOrderQRCode({ orderNumber, totalAmount }) {
  return (
    <div style={{ textAlign: "center" }}>
      <QRCode
        value={`BCD\n001\n1\nSCT\n${BANK_DATA.bic}\n${
          BANK_DATA.owner
        }\n${BANK_DATA.iban.replaceAll(
          " ",
          ""
        )}\nEUR${totalAmount}.00\n\n${orderNumber}\n\n`}
        size={200}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
        renderAs={"svg"}
      />
    </div>
  );
}
