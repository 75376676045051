import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import {
  buttonText,
  orderPageText,
  pageText,
  reviewPageText,
} from "../../helpers/pageSettings";
import { calcShippingCost, calcTotalAmount } from "../../helpers/orderHelper";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review({
  orderState,
  handleNext,
  language,
  handleChangeOrderState,
}) {
  const classes = useStyles();
  const { firstName, lastName, address1, zip, city, country } =
    orderState.contact;

  const handleNextStep = () => {
    let newOrderState = {
      ...orderState,
      totalAmount: parseInt(
        calcTotalAmount(
          language,
          orderState.contact.country,
          orderState.order.totalOrders
        )
      ),
    };
    console.log("newOrderState :>> ", newOrderState);
    handleChangeOrderState(newOrderState);
    handleNext();
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {reviewPageText.title[language]}
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={pageText.book[language]}
            secondary={"1. - 11. Hyong"}
          />
          <Typography variant="body2">{`${orderState.order.totalOrders}x 70${
            language === "de" ? "," : "."
          }00 €`}</Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={reviewPageText.shipping[language]}
            secondary={reviewPageText.shippingType[language]}
          />
          <Typography variant="body2">
            1x {calcShippingCost(language, orderState.contact.country)} €
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={orderPageText.total[language]} />
          <Typography variant="subtitle1" className={classes.total}>
            {calcTotalAmount(
              language,
              orderState.contact.country,
              orderState.order.totalOrders
            )}{" "}
            €
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {reviewPageText.text.shipping[language]}
          </Typography>
          <Typography gutterBottom>
            {firstName} {lastName}
          </Typography>
          <Typography gutterBottom>{address1}</Typography>
          <Typography gutterBottom>
            {zip}, {city}
          </Typography>
          <Typography gutterBottom>{country}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            {reviewPageText.text.payment[language]}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom>
                {reviewPageText.text.paymentType[language]}
              </Typography>
              <Typography variant="body1">
                {reviewPageText.text.paymentDetails[language]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center", margin: 10 }} item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNextStep()}
          >
            {buttonText.end[language]}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
