import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddressForm from "./AddressForm";
// import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { useNavigate } from "react-router-dom";
import Order from "./Order";
import useWindowDimensions from "../../helpers/windowDimensions";
import { Grid, MobileStepper, useTheme } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import booOrderService from "../../api/services/booOrder.service";
import Copyright from "../../helpers/Copyright";
import Loading from "../../helpers/Loading";
import {
  buttonText,
  langTextSize,
  pageText,
  steps,
} from "../../helpers/pageSettings";
import Overview from "./Overview";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  rootMobileStepper: {
    // maxWidth: 400,
    flexGrow: 1,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function getStepContent(
  step,
  orderState,
  handleChangeOrderState,
  handleNext,
  language
) {
  switch (step) {
    case 0:
      return (
        <Order
          orderState={orderState}
          handleChangeOrderState={handleChangeOrderState}
          language={language}
        />
      );
    case 1:
      return (
        <AddressForm
          orderState={orderState}
          handleChangeOrderState={handleChangeOrderState}
          handleNext={handleNext}
          language={language}
        />
      );
    // case 2:
    //   return (
    //     <PaymentForm
    //       orderState={orderState}
    //       handleChangeOrderState={handleChangeOrderState}
    //     />
    //   );
    case 2:
      return (
        <Review
          handleNext={handleNext}
          orderState={orderState}
          handleChangeOrderState={handleChangeOrderState}
          language={language}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

export default function Checkout({ language, handleSwitchLanguage }) {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const classes = useStyles();
  let navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [orderState, setOrderState] = React.useState({
    order: {
      orders: 0,
      dedicationOrders: [],
      totalOrders: 0,
      checkedDedication: false,
    },
    orderNumber: null,
    totalAmount: 0,
    contact: {},
    payment: { type: "UEBERWEISUNG" },
  });

  const handleChangeOrderState = (newState) => {
    setOrderState({ ...newState });
  };

  const handleNext = () => {
    // Kostenpflichtig bestellen:
    if (activeStep === steps["de"].length - 1) {
      // Start Loading here
      setLoading(true);
      handlePlaceOrder();
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handlePlaceOrder = () => {
    // // DEBUG:
    // setActiveStep(activeStep + 1);
    // setLoading(false);

    booOrderService
      .createBookOrder(orderState)
      .then((res) => {
        setOrderState((currentState) => ({
          ...currentState,
          orderNumber: res.data.orderNumber,
        }));
        // For test purposes comment setActiveStep to send multiple Orders
        setActiveStep(activeStep + 1);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error:>> ", e);
        alert(pageText.alert[language]);
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {loading && <Loading />}
      <AppBar position="relative" color="secondary" className={classes.appBar}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div>
            <Typography
              style={{ fontFamily: "Verdana", cursor: "pointer" }}
              variant="h4"
              color="inherit"
              onClick={() => navigate("/")}
              noWrap
            >
              {pageText.book[language]}
            </Typography>
          </div>
          <div>
            <Grid style={{ margin: 0 }} spacing={width < 500 ? 1 : 3} container>
              <Grid item xs={4}>
                <Typography
                  style={{
                    cursor: "pointer",
                    color: language === "de" ? "white" : "grey",
                    fontSize:
                      width < 500 ? langTextSize.mobile : langTextSize.desktop,
                  }}
                  onClick={() => handleSwitchLanguage("de")}
                  variant="h6"
                  color="inherit"
                  align="right"
                >
                  de
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  style={{
                    fontSize:
                      width < 500 ? langTextSize.mobile : langTextSize.desktop,
                  }}
                  variant="h6"
                  align="center"
                  color="inherit"
                >
                  |
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  style={{
                    cursor: "pointer",
                    color: language === "en" ? "white" : "grey",
                    fontSize:
                      width < 500 ? langTextSize.mobile : langTextSize.desktop,
                  }}
                  onClick={() => handleSwitchLanguage("en")}
                  variant="h6"
                  align="left"
                >
                  en
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            {pageText.order[language]}
          </Typography>
          {width >= 500 ? (
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps[language].map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            <br />
          )}
          <React.Fragment>
            {activeStep === steps["de"].length ? (
              <Overview language={language} orderState={orderState} />
            ) : (
              <React.Fragment>
                {getStepContent(
                  activeStep,
                  orderState,
                  handleChangeOrderState,
                  handleNext,
                  language
                )}
                {width < 500 ? (
                  <MobileStepper
                    variant="progress"
                    steps={steps["de"].length}
                    position="static"
                    activeStep={activeStep}
                    className={classes.rootMobileStepper}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={
                          orderState.order.totalOrders === 0 ||
                          activeStep === 1 ||
                          activeStep === steps["de"].length - 1
                        }
                      >
                        {buttonText.next[language]}
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        {buttonText.back[language]}
                      </Button>
                    }
                  />
                ) : (
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        {buttonText.back[language]}
                      </Button>
                    )}
                    {activeStep !== 1 &&
                      activeStep !== steps["de"].length - 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            orderState.order.totalOrders === 0 ||
                            activeStep === 1 ||
                            activeStep === steps["de"].length - 1
                          }
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps["de"].length - 1
                            ? buttonText.end[language]
                            : buttonText.next[language]}
                        </Button>
                      )}
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}
