import React from "react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CheckSlide() {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <CheckBoxIcon
          style={{
            color: "green",
            backgroundColor: "white",
            fontSize: "20rem",
            borderRadius: "10%",
          }}
        />
      </Backdrop>
    </div>
  );
}
