import axios from "axios";
import { authHeader } from "./services/auth-header";

var SERVER_URL = "/api";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // development code
  // Mono-Loco:
  // SERVER_URL = "http://localhost:35618/api";
  // Mono-Loco:
  // SERVER_URL = "http://localhost:5000/api";
  // TWIN-BookShop:
  SERVER_URL = "http://localhost:35610/api";
  // Happy-Test:
  // SERVER_URL = "http://localhost:35911/api";
} else {
  // production code
  SERVER_URL = "/api";
}

export const httpCommon = axios.create({
  baseURL: SERVER_URL,
  timeout: 50000,
  headers: {
    "Content-type": "application/json",
  },
});

export const httpCommonAuth = axios.create({
  baseURL: SERVER_URL,
  timeout: 50000,
  headers: {
    "Content-type": "application/json",
    ...authHeader(),
  },
  // headers: {
  //   common: {},
  //   post: {
  //     // can be common or any other method
  //     header1: "value1",
  //   },
  // },
});

// axios.defaults.headers.post["header1"] = "value"; // for POST requests
// axios.defaults.headers.common["header1"] = "value"; // for all requests

export const httpUpload = axios.create({
  // baseURL: process.env.REACT_APP_SERVER_IP,
  baseURL: SERVER_URL,
  timeout: 50000,
  headers: {
    "Content-type": "application/json",
    ...authHeader(),
  },
  // headers: {
  //   "Content-type": "application/json",
  // },
});
