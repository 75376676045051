import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Image1 from "../../imgs/book1.jpg"; // Import using relative path
// import DescriptionIMG from "../../imgs/description/Erklaerung.jpg"; // Import using relative path
import DescriptionIMG from "../../imgs/description/page_hyong11.jpg"; // Import using relative path
// import FiguresIMG from "../../imgs/description/figuren.jpg"; // Import using relative path
import FiguresIMG from "../../imgs/description/page_hyong7.jpg"; // Import using relative path
import MovementIMG from "../../imgs/description/page_bewegung.jpg"; // Import using relative path
import { useNavigate } from "react-router-dom";
import Copyright from "../../helpers/Copyright";
import useWindowDimensions from "../../helpers/windowDimensions";
import BookDescription from "../bookDescription/BookDescription";
import { langTextSize } from "../../helpers/pageSettings";
import BasicImageList from "./BasicImageList";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundImage: `url(${Image1})`,
    backgroundColor: "#cccccc",
    height: 600,
    backgroundPosition: "center",
    backgroundPositionY: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    position: "absolute",
    bottom: "10%",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const bookCards = [
  {
    image: DescriptionIMG,
    title: {
      de: "Verständliche Erklärung",
      en: "Understandable explanation",
    },
    description: {
      de: "Das Buch verfügt über eine klare und deutliche Struktur. Dadurch wird das Verständnis der Techniken erleichtert.",
      en: "Because of a consistent structure, techniques are easy to understand.",
    },
  },
  {
    image: FiguresIMG,
    title: { de: "Anschauliche Darstellungen", en: "Clear representations" },
    description: {
      de: "Qualitativ hochwertige Bilder vermitteln die Ausübung der Bewegungen.",
      en: "Movements are conveyed by high-quality images.",
    },
  },
  {
    image: MovementIMG,
    title: { de: "Bewegungslehre", en: "Movement Theory" },
    description: {
      de: "Eine effektive Bewegung setzt eine zielgerichtete Gymnastik voraus.",
      en: "The prerequisite for effective movement is focused, mindful gymnastics.",
    },
  },
];

export default function FrontPage({ language, handleSwitchLanguage }) {
  const { width } = useWindowDimensions();

  let navigate = useNavigate();
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color="secondary" position="relative">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div>
            <Typography
              style={{
                fontFamily: "Verdana",
                fontSize: width < 500 ? "5vw" : "2.125rem",
              }}
              variant="h4"
              color="inherit"
              noWrap
            >
              {language === "de" ? "Taekwondo-Buch" : "Taekwondo-Book"}
            </Typography>
          </div>
          <div>
            <Grid style={{ margin: 0 }} spacing={width < 500 ? 1 : 3} container>
              <Grid item xs={4}>
                <Typography
                  style={{
                    cursor: "pointer",
                    color: language === "de" ? "white" : "grey",
                    fontSize:
                      width < 500 ? langTextSize.mobile : langTextSize.desktop,
                  }}
                  onClick={() => handleSwitchLanguage("de")}
                  variant="h6"
                  color="inherit"
                  align="right"
                >
                  de
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  style={{
                    fontSize:
                      width < 500 ? langTextSize.mobile : langTextSize.desktop,
                  }}
                  variant="h6"
                  align="center"
                  color="inherit"
                >
                  |
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  style={{
                    cursor: "pointer",
                    color: language === "en" ? "white" : "grey",
                    fontSize:
                      width < 500 ? langTextSize.mobile : langTextSize.desktop,
                  }}
                  onClick={() => handleSwitchLanguage("en")}
                  variant="h6"
                  align="left"
                >
                  en
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            ></Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            ></Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    onClick={() => {
                      navigate("/bestellen");
                    }}
                    variant="contained"
                    color="primary"
                    style={{ fontSize: "200%" }}
                  >
                    {language === "de" ? "Buch bestellen" : "Order Book"}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <BookDescription language={language} />
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {bookCards.map((card, i) => (
              <Grid item key={"card-" + i} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.title[language]}
                    </Typography>
                    <Typography>{card.description[language]}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <div style={{ padding: 100 }}>
        <BasicImageList />
      </div>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          {language === "de" ? "Taekwondo-Buch" : "Taekwondo-Book"}
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="textSecondary"
          component="p"
        >
          {language === "de" ? "Taekwondo-Buch" : "Taekwondo-Book"} Shop by GG
          Maier
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
