import { digSeparator } from "./pageSettings";

export const calcShippingCost = (language, country) => {
  let cost = 7;
  console.log("country :>> ", country);
  if (!["DE"].includes(country)) {
    cost = 20;
  }
  if (["CH", "AT"].includes(country)) {
    cost = 15;
  }
  cost = cost + digSeparator(language) + "00";
  return cost;
};

export const calcTotalAmount = (language, country, totalOrders) => {
  let cost = 7;
  if (!["DE"].includes(country)) {
    cost = 20;
  }
  if (["CH", "AT"].includes(country)) {
    cost = 15;
  }
  cost = cost + 70 * totalOrders;
  cost = cost.toString() + digSeparator(language) + "00";
  return cost;
};
