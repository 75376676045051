import Cover01IMG from "../../imgs/imgList/cover_front_up.jpg"; // Import using relative path
import BewegungIMG from "../../imgs/imgList/page_bew-tafel.jpg";
import VorwortIMG from "../../imgs/imgList/page_vorwort.jpg";
import PicIMG from "../../imgs/imgList/picture_page.jpg";
import EumJangIMG from "../../imgs/imgList/sw_omjang.jpg";
import Cover02IMG from "../../imgs/imgList/cover_front.jpg";

export const itemData = [
  {
    img: Cover01IMG,
    title: "Cover01",
    author: "George Maier",
    cols: 2,
  },
  {
    img: BewegungIMG,
    title: "Bewegung",
    author: "George Maier",
    cols: 1,
  },
  {
    img: VorwortIMG,
    title: "Vorwort",
    author: "George Maier",
    cols: 1,
  },
  {
    img: PicIMG,
    title: "Pictures",
    author: "George Maier",
    cols: 2,
  },
  {
    img: EumJangIMG,
    title: "EumJang-sw",
    author: "George Maier",
    cols: 2,
  },
  {
    img: Cover02IMG,
    title: "Cover02",
    author: "George Maier",
    cols: 1,
  },
];
