import { Button, Typography } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { calcTotalAmount } from "../../helpers/orderHelper";
import { buttonText, overViewPageText } from "../../helpers/pageSettings";
import Bankdetails from "./Bankdetails";
import BookOrderQRCode from "./BookOrderQRCode";

export default function Overview({ orderState, language }) {
  let navigate = useNavigate();

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {overViewPageText.title[language]}.
      </Typography>
      <Typography variant="subtitle1">
        {overViewPageText.order.title[language]}
        <br />
        {overViewPageText.order.orderNumberText[language]}:{" "}
        <b>{orderState.orderNumber}</b>
        <br />
        {overViewPageText.order.transferText1[language]} <br />
        <b>
          {calcTotalAmount(
            language,
            orderState.contact.country,
            orderState.order.totalOrders
          )}{" "}
          €
        </b>
        <br />
        {overViewPageText.order.transferText2[language]}
      </Typography>
      <br />
      <Bankdetails
        orderNumber={orderState.orderNumber}
        totalAmount={orderState.totalAmount}
      />
      <br />
      <Typography style={{ margin: 25 }} variant="subtitle1">
        {overViewPageText.qr.text[language]}
      </Typography>
      <BookOrderQRCode
        orderNumber={orderState.orderNumber}
        totalAmount={orderState.totalAmount}
      />
      <div style={{ margin: 25 }}>
        <Typography>{overViewPageText.shipping[language]}</Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            navigate("/");
          }}
          variant="contained"
          color="primary"
        >
          {buttonText.exit[language]}
        </Button>
      </div>
    </div>
  );
}
