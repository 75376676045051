import { httpCommon } from "../http.service";

const API_PATH = "/bookorder";

class BookOrderDataService {
  createBookOrder(data) {
    return httpCommon.post(API_PATH + "/create", data);
  }

  update(id, data) {
    return httpCommon.put(`${API_PATH}/${id}`, data);
  }

  delete(id) {
    return httpCommon.delete(`${API_PATH}/${id}`);
  }
}

export default new BookOrderDataService();
