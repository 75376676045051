import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import { Button } from "@material-ui/core";
import { addressFormText } from "../../helpers/pageSettings";
import ReactFlagsSelect from "react-flags-select";

export default function AddressForm({
  orderState,
  handleChangeOrderState,
  handleNext,
  language,
}) {
  const [state, setState] = React.useState({ ...orderState.contact });
  const [select, setSelect] = React.useState("DE");
  const onSelect = (code) => setSelect(code);

  const formik = useFormik({
    initialValues: {
      ...state,
    },
    onSubmit: (values) => {
      setState(values);
      handleChangeOrderState({
        ...orderState,
        contact: { ...values, country: select },
      });
      handleNext();
    },
  });

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {addressFormText.title[language]}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label={addressFormText.labels.fN[language]}
              fullWidth
              autoComplete="given-name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label={addressFormText.labels.lN[language]}
              fullWidth
              autoComplete="family-name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="mail"
              name="mail"
              label="E-Mail"
              fullWidth
              autoComplete="e-mail"
              value={formik.values.mail}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              label={addressFormText.labels.phone[language]}
              fullWidth
              autoComplete="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </Grid>
          <br />
          <br />
          <Grid item xs={12}>
            <Typography variant="body1">
              {addressFormText.labels.country[language]} *
            </Typography>
            <ReactFlagsSelect
              selected={select}
              onSelect={onSelect}
              placeholder={"Land auswählen"}
              searchable
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              label={addressFormText.labels.address1[language]}
              fullWidth
              autoComplete="shipping address-line1"
              value={formik.values.address1}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address2"
              label={addressFormText.labels.address2[language]}
              fullWidth
              autoComplete="shipping address-line2"
              value={formik.values.address2}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label={addressFormText.labels.zip[language]}
              fullWidth
              autoComplete="shipping postal-code"
              value={formik.values.zip}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label={addressFormText.labels.city[language]}
              fullWidth
              autoComplete="shipping address-level2"
              value={formik.values.city}
              onChange={formik.handleChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <TextField
              required
              id="country"
              name="country"
              label={addressFormText.labels.country[language]}
              fullWidth
              autoComplete="shipping country"
              value={formik.values.country}
              onChange={formik.handleChange}
            />
          </Grid> */}
          <Button color="primary" variant="contained" fullWidth type="submit">
            {addressFormText.submit[language]}
          </Button>
        </Grid>
      </form>
      <br />
    </React.Fragment>
  );
}
