import { Typography } from "@material-ui/core";
import React from "react";

export default function BookDescription({ language }) {
  const text = {
    de: {
      header: "Mehrsprachige Beschreibung",
      sub: "Das Buch beinhaltet Beschreibungen auf englisch, deutsch und koreanisch.",
    },
    en: {
      header: "Multilingual Description",
      sub: "All Actions are descriped in English, German and Korean",
    },
  };

  if (language === "de" || language === "en") {
    return (
      <div style={{ marginTop: 55, padding: 25, backgroundColor: "lightgrey" }}>
        <Typography align="center" variant={"h5"}>
          {text[language].header}
        </Typography>
        <Typography align="center" variant={"body1"}>
          {text[language].sub}
        </Typography>
      </div>
    );
  } else return <></>;
}
